import { useState, useEffect, useRef } from 'react';
import './style.scss';
import {
  Button,
  Col,
  Form,
  Input,
  Row
} from "reactstrap";
import Select from "react-select";
import { Editor } from '@tinymce/tinymce-react';

const Step2 = ({ handleChildStep2, property }) => {
  const editorRef = useRef(null);
  const [name, setName] = useState('');
  const [urlParameter, setUrlParameter] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState([]);
  const [statesAddress, setStatesAddress] = useState([])
  const [citiesAddress, setCitiesAddress] = useState([])
  const [country, setCountry] = useState('Brasil');
  const [city, setCity] = useState([]);
  const [description, setDescription] = useState('');
  const [video, setVideo] = useState('');
  const [tour, setTour] = useState('');
  const [appType, setAppType] = useState('')
  const [qaPairs, setQAPairs] = useState([{ question: '', answer: '' }]);
  const [atualQaPairs, setAtualQAPairs] = useState([]);
  const [extraTag, setExtraTag] = useState('');
  const [seoDescription, setSeoDescription] = useState('');

  const dataToSend = {
    name,
    urlParameter,
    cep,
    address,
    number,
    complement,
    district,
    city,
    description,
    video,
    tour,
    state,
    country,
    qaPairs,
    appType,
    atualQaPairs,
    extraTag,
    seoDescription
  };

  useEffect(() => {
    handleChildStep2(dataToSend);
  }, [name, 
      urlParameter, 
      cep, 
      address, 
      number, 
      complement, 
      district, 
      city,
      description,
      video,
      state,
      country,
      qaPairs,
      appType,
      atualQaPairs,
      extraTag,
      seoDescription,
      tour])

      const handleAtualQuestionChange = (e, index) => {
        const updatedQAPairs = [...atualQaPairs];
        updatedQAPairs[index].question = e.target.value;
        setAtualQAPairs(updatedQAPairs);
      };
      
      const handleAtualAnswerChange = (e, index) => {
        const updatedQAPairs = [...atualQaPairs];
        updatedQAPairs[index].response = e.target.value;
        setAtualQAPairs(updatedQAPairs);
      };

      const handleQuestionChange = (e, index) => {
        const updatedQAPairs = [...qaPairs];
        updatedQAPairs[index].question = e.target.value;
        setQAPairs(updatedQAPairs);
      };
      
      const handleAnswerChange = (e, index) => {
        const updatedQAPairs = [...qaPairs];
        updatedQAPairs[index].answer = e.target.value;
        setQAPairs(updatedQAPairs);
      };
      
      const handleAddQA = () => {
        setQAPairs([...qaPairs, { question: '', answer: '' }]);
      };

      const handleRemoveQA = (index) => {
        const updatedQAPairs = qaPairs.filter((_, i) => i !== index);
        setQAPairs(updatedQAPairs);
      };

      const handleRemoveAtualQuestion = (id) => {
        fetch(process.env.REACT_APP_API_URL + '/delete-property-question/' + id, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(response => response.json())
        .then(async resp => {
          setAtualQAPairs(property?.questions.filter(question => question.id !== id))
        })
      }
      
      // useEffect(() => {
      //   handleChildStep2(qaPairs);
      // }, [qaPairs]);

      useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
        .then((res) => res.json())
        .then(response => {
          const states = []
          for(var i = 0; response.length > i; i++){
            states.push({
              value: response[i].sigla,
              label: response[i].nome
            })
          }
          setStatesAddress(states)
        })
      }, [])

      useEffect(() => {
        if(state.value && state.value !== 'null'){
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + state.value + '/municipios',)
        .then((res) => res.json())
        .then(response => {
          const cities = []
          for(var i = 0; response.length > i; i++){
            cities.push({
              value: response[i].nome,
              label: response[i].nome
            })
          }
          setCitiesAddress(cities)
        })
      }
      }, [state])

      const consultaCep = cepValue => {
        const cep = cepValue
          if (cep?.length !== 8) {
          setAddress('');
          setDistrict('');
          setState('')
          setCity('');
          }else{
            fetch('https://viacep.com.br/ws/' + cep + '/json/')
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                  setAddress('');
                  setDistrict('');
                  setState('')
                  setCity('');
                } else {
                  setAddress(data.logradouro);
                  setDistrict(data.bairro);
                  setState({
                    value:data.uf,
                    label:data.uf
                  })
                  setCity({
                    value:data.localidade,
                    label:data.localidade
                  });
                }
              })
          }  
      }

      useEffect(() => {
        setName(property?.name)
        setUrlParameter(property?.url_parameter)
        setExtraTag(property?.extra_tag)
        setSeoDescription(property?.seo_description)
        setCep(property?.cep)
        setAddress(property?.address)
        setNumber(property?.number)
        setComplement(property?.complement)
        setDistrict(property?.district)
        setState({value: property?.state, label: property?.state})
        setCountry(property?.country)
        setCity({value: property?.city, label: property?.city})
        setDescription(property?.description)
        setVideo(property?.video)
        setTour(property?.tour)
        setAppType(property?.type)
        setAtualQAPairs(property?.questions)
      },[property])
      
      // const [qaPairs, setQAPairs] = useState([{ question: '', answer: '' }]);

  return(
    <div>
      <Form>
        <Row>
              <h5 className="title-imovel">Sobre o imóvel</h5>
              <Row style={{margin:'30px 0'}}>
                {(property?.type !== 'house' && property?.type !== 'land_shed' && property?.type !== 'terrain' && property?.type !== 'terrain_land_shed') &&
                    <Col md={6}>
                        <label htmlFor="txtFirstNameShipping"
                          className="col-form-label">Tipo de apartamento</label>
                          <Input 
                            type="select" 
                            id="ddlCreditCardType" 
                            name="ddlCreditCardType"
                            className="form-select"
                            value={appType}
                            onChange={e => setAppType(e.target.value)}
                          >
                            <option value="">Selecione o Status</option>
                            <option value={'apartament_dif'}>Diferenciados</option>
                            <option value={'facing_sea'}>Apartamentos frente mar</option>
                            <option value={'sea_court'}>Apartamentos quadra mar</option>
                            <option value={'plant'}>Apartamentos na planta</option>
                            <option value={'roof'}>Coberturas</option>
                            <option value={'others_apartament'}>Apartamentos geral</option>
                          </Input>
                          <small>Se não for adicionado um tipo, o imóvel não será encontrado na busca do site.</small>
                    </Col>
                }

                <Col md={6}>
                    <label htmlFor="txtFirstNameShipping"
                      className="col-form-label">Nome do Imóvel</label>
                      <Input 
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="Nome"
                        type="text" 
                        className="form-control" 
                        value={name}
                        onChange={e => {
                          setName(e.target.value)
                          setUrlParameter(e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s-]/g, "").replace(/\s+/g, '-').trim())
                        }}
                      />
                    <small>O nome deve ser composto por palavras chave.</small>
                </Col>
                <Col md={6}>
                    <label htmlFor="txtCompanyShipping" className="col-form-label">Url</label>
                      <Input 
                        id="txtCompanyShipping" 
                        name="txtCompanyShipping" 
                        placeholder="Url"
                        type="text" 
                        className="form-control" 
                        value={urlParameter}
                        onChange={e => setUrlParameter(e.target.value)}
                      />
                </Col>
                <Col md={6}>
                  <label htmlFor="txtCompanyShipping" className="col-form-label">Tag destaque</label>
                  <Input 
                    id="txtCompanyShipping" 
                    name="txtCompanyShipping" 
                    placeholder="Ex.: Churrasqueira à carvão"
                    type="text" 
                    className="form-control" 
                    value={extraTag}
                    onChange={e => setExtraTag(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="txtCompanyShipping" className=" col-form-label">Tag destaque</label>
                  <Input 
                    id="txtCompanyShipping" 
                    name="txtCompanyShipping" 
                    placeholder="Descrição com palavras chave para SEO"
                    type="textarea" 
                    maxLength={255}
                    className="form-control" 
                    value={seoDescription}
                    onChange={e => setSeoDescription(e.target.value)}
                  />
                </Col>
                <Col md={12} style={{margin:'10px 0 50px'}}>
                <label htmlFor="txtCompanyShipping" className="col-form-label">Descrição</label>
                <Editor
                  apiKey='w3485derw88wqxe25q8jpatcv6be8cvrbwkrta6n2f63trk2'
                  onInit={(evt, editor) => editorRef.current = editor}
                  onChange={() => 
                    editorRef.current &&
                      setDescription(editorRef.current.getContent())
                  }
                  initialValue={description}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'blocks | a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
                </Col>
                <Row>
                  <Col md={12}>
                    <h5 className="title-imovel-endereco">Perguntas e respostas</h5>
                    {atualQaPairs?.map((question, index) => (
                      <Row style={{ margin: '30px 0' }} key={index}>
                        <Col md={5}>
                          <Row>
                            <label htmlFor={`question_${index}`} className="col-form-label col-lg-3">
                              Pergunta
                            </label>
                            <div className="col-lg-9">
                              <Input
                                name={`question_${index}`}
                                placeholder="Pergunta"
                                type="text"
                                className="form-control"
                                value={question?.question}
                                onChange={(e) => handleAtualQuestionChange(e, index)}
                              />
                            </div>
                          </Row>
                        </Col>
                        <Col md={5}>
                          <Row>
                            <label htmlFor={`answer_${index}`} className="col-form-label col-lg-3">
                              Resposta
                            </label>
                            <div className="col-lg-9">
                              <Input
                                name={`answer_${index}`}
                                placeholder="Resposta"
                                type="text"
                                className="form-control"
                                value={question?.response}
                                onChange={(e) => handleAtualAnswerChange(e, index)}
                              />
                            </div>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <Button onClick={() => handleRemoveAtualQuestion(question?.id)}>Remover</Button>
                        </Col>
                      </Row>
                    ))}
                    <Button onClick={handleAddQA}>Adicionar Pergunta</Button>
                    {qaPairs?.map((pair, index) => (
                      <Row style={{ margin: '30px 0' }} key={index}>
                        <Col md={5}>
                          <Row>
                            <label htmlFor={`question_${index}`} className="col-form-label col-lg-3">
                              Pergunta
                            </label>
                            <div className="col-lg-9">
                              <Input
                                name={`question_${index}`}
                                placeholder="Pergunta"
                                type="text"
                                className="form-control"
                                value={pair.question}
                                onChange={(e) => handleQuestionChange(e, index)}
                              />
                            </div>
                          </Row>
                        </Col>
                        <Col md={5}>
                          <Row>
                            <label htmlFor={`answer_${index}`} className="col-form-label col-lg-3">
                              Resposta
                            </label>
                            <div className="col-lg-9">
                              <Input
                                name={`answer_${index}`}
                                placeholder="Resposta"
                                type="text"
                                className="form-control"
                                value={pair.answer}
                                onChange={(e) => handleAnswerChange(e, index)}
                              />
                            </div>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <Button onClick={() => handleRemoveQA(index)}>Remover</Button>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Row>
            </Row>
            <Row>
              <h5 className="title-imovel-endereco">Endereço do imóvel</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">CEP</label>
                    <div className="col-lg-9">
                      <Input
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="CEP"
                        type="tel"
                        pattern="[0-9]*"
                        className="form-control" 
                        value={cep}
                        onChange={e => {
                          setCep(e.target.value.replace(/\D/g, ''))
                          consultaCep(e.target.value.replace(/\D/g, ''))
                        }}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtLastNameShipping"
                      className="col-lg-3 col-form-label">Nome da Rua</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtLastNameShipping" 
                        name="txtLastNameShipping" 
                        placeholder="Nome da Rua"
                        type="text" 
                        className="form-control" 
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Nº</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCompanyShipping" 
                      name="txtCompanyShipping" 
                      placeholder="Número"
                      type="number" 
                      className="form-control" 
                      value={number}
                      onChange={e => setNumber(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtEmailAddressShipping"
                    className="col-lg-3 col-form-label">Complemento</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtEmailAddressShipping" 
                      name="txtEmailAddressShipping" 
                      type="text" 
                      className="form-control" 
                      placeholder="Complemento" 
                      value={complement}
                      onChange={e => setComplement(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Bairro</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Bairro"
                      className="form-control" 
                      value={district}
                      onChange={e => setDistrict(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Cidade</label>
                  <div className="col-lg-9">
                    <Select
                        value={city}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setCity(e)
                        }}
                        options={citiesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Estado</label>
                  <div className="col-lg-9">
                    <Select
                        value={state}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setState(e)
                        }}
                        options={statesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">País</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="País"
                      className="form-control" 
                      value={country}
                      onChange={e => setCountry(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
        </Row>

        <Row>
            <h5 className="title-video">Video e Tour 360º</h5>
            <Row style={{margin:'30px 0'}}>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-3 col-form-label">Iframe do Vídeo</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="<iframe....."
                      className="form-control" 
                      value={video}
                      onChange={(e) => setVideo(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-3 col-form-label">Link Tour 360º</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="https://www....."
                      className="form-control" 
                      value={tour}
                      onChange={(e) => setTour(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </Row>
      </Form>
    </div>
  )
}

export default Step2;